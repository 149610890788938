body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    background-color: var(--gray-background-color);
}

a {
    text-decoration: none;
}

section {
    margin: 0 auto;
    padding: 50px 25px;
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../public/fonts/Poppins-400.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: bold;
    src: url('../public/fonts/Poppins-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/Poppins-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/Poppins-800.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../public/fonts/OpenSans-300.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/OpenSans-400.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/OpenSans-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/OpenSans-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/OpenSans-800.woff2') format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: italic;
    src: url('../public/fonts/OpenSans-italic.woff2') format('woff2');
}
